import { trackUserId, useTracking } from './index';
import { RequestVariant } from '../components/community-assist/community-assist.component';

export const usePageTracking = () => {
  const { trackEvent } = useTracking();
  return {
    trackPage: (path?: string) => trackEvent({ name: 'page_visit', path: path })
  };
};

export const useOnboardingTracking = () => {
  const { trackEvent } = useTracking();

  const trackHobbies = (
    action: 'next' | 'skip',
    selected?: boolean,
    selectedCustom?: boolean
  ) => {
    trackEvent({
      name: 'onboarding_hobbies',
      properties: {
        action: action,
        selected: selected || null,
        selected_custom: selectedCustom || null
      }
    });
  };

  const trackNeedHelp = (action: 'next' | 'skip', text?: boolean) => {
    trackEvent({
      name: 'onboarding_need_help',
      properties: { action: action, text: text || false }
    });
  };

  const trackExpertize = (action: 'next' | 'skip', text: boolean) => {
    trackEvent({
      name: 'onboarding_expertize',
      properties: { action: action, text: text }
    });
  };

  return {
    trackHobbies,
    trackNeedHelp,
    trackExpertize
  };
};

export const useMembersTracking = () => {
  const { trackEvent } = useTracking();
  const trackFilters = (action: 'open' | 'apply', isOpen?: boolean) => {
    trackEvent({
      name: 'filters',
      properties: {
        action: action,
        isOpen: isOpen
      }
    });
  };
  const trackSearch = () => trackEvent({ name: 'search' });
  return {
    trackFilters,
    trackSearch
  };
};

export enum EventIntroType {
  Intro = 'intro',
  NeedHelpResponse = 'need_help_response',
  ExpertiseResponse = 'expertise_response'
}

type IntroAction = 'open' | 'cancel' | 'send' | 'close';

export const useIntroTracking = () => {
  const { trackEvent } = useTracking();
  const trackIntro = (
    type: EventIntroType,
    action: IntroAction,
    toUserId: string,
    text?: boolean
  ) => {
    trackEvent({
      name: 'connect',
      properties: {
        action: action,
        type: type,
        toUserId: trackUserId(toUserId),
        text: text
      }
    });
  };
  return {
    trackIntro
  };
};

export enum EventAuthType {
  Login = 'email_login',
  Register = 'email_register',
  OAuth = 'oauth'
}

type AuthAction = 'started' | 'succeed';

export const useAuthTracking = () => {
  const { trackEvent } = useTracking();
  const trackAuth = (
    type: EventAuthType,
    action: AuthAction,
    props?: { [key: string]: any }
  ) => {
    trackEvent({
      name: 'auth',
      properties: { type: type, action: action, ...props }
    });
  };
  return {
    trackAuth
  };
};

export const useSaveLinkedinTracking = () => {
  const { trackEvent } = useTracking();
  const trackSaveLinkedin = (action: 'started' | 'succeed') => {
    trackEvent({
      name: 'save_linkedin',
      properties: { action: action }
    });
  };
  return { trackSaveLinkedin };
};

type ProfileRequestAction = 'edit' | 'create' | 'edited' | 'created';

export const useProfileRequestTracking = () => {
  const { trackEvent } = useTracking();
  const trackProfileRequest = (
    type: RequestVariant,
    action: ProfileRequestAction
  ) => {
    trackEvent({
      name: 'profile_request',
      properties: { type: type, action: action }
    });
  };
  return {
    trackProfileRequest
  };
};

export const useProfileTracking = () => {
  const { trackEvent } = useTracking();
  const trackProfileDelete = () =>
    trackEvent({ name: 'profile_delete_btn_clicked' });
  const trackProfileEdit = () =>
    trackEvent({ name: 'profile_edit_btn_clicked' });
  return {
    trackProfileDelete,
    trackProfileEdit
  };
};
