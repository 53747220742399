// deprecated, will be removed along with /pages
// use app/_tracking for /app
import * as amplitude from '@amplitude/analytics-browser';
import { CurrentUserType } from 'types';
import { useAuth } from 'contexts/auth';
import { usePathname } from 'next/navigation';
import { resolveRoute } from 'utils/routes';
import { useCommunity } from 'contexts/community';


export const initAmplitude = () => {
  const key = process.env.AMPLITUDE_API_KEY;
  if (!key) return;
  amplitude.init(key, undefined, {
    defaultTracking: {
      pageViews: true,
      sessions: true
    }
  });
};

export const identifyUser = (user: CurrentUserType) => {
  const identifyEvent = new amplitude.Identify();

  identifyEvent.set('name', user.firstName || '-');

  amplitude.identify(identifyEvent, {
    user_id: trackUserId(user.id)
  });
};

export interface trackEventType {
  name: string;
  user?: CurrentUserType | null;
  properties?: { [key: string]: any };
  options?: {};
  path?: string;
}

export const trackUserId = (userId: string | number | undefined) => {
  if (!userId) return;
  return `user_${userId}`;
};

export const useTracking = () => {
  const { currentUser } = useAuth();
  const { community } = useCommunity();
  const pathname = usePathname();

  const trackEvent = (props: trackEventType) => {
    const path = props.path || pathname || '';
    const route = resolveRoute(path);
    if (!props.user) {
      props.user = currentUser;
    }

    amplitude.track(
      props.name,
      {
        pageName: route?.name,
        pagePath: path,
        community: community?.subdomain,
        ...props.properties
      },
      {
        user_id: props.user ? trackUserId(props.user.id) : undefined,
        ...props.options
      }
    );
  };

  return {
    trackEvent
  };
};
