// deprecated, will be removed along with the /pages folder
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { CurrentCommunityType } from '../types';
import { useAuth } from './auth';
import { TELEGRAM_SUBDOMAIN_SUFFIX } from '../components/telegram';
import { useRouter } from 'next/router';

type CommunityContextType = {
  community: CurrentCommunityType | null;
};

export const CommunityContext = createContext({
  community: null
} as CommunityContextType);


export const APP_SUBDOMAIN = 'app';
const STOP_SUBDOMAINS = ['www'];

export const CommunityContextProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useAuth();
  const router = useRouter();

  const community = useMemo(() => {
    if (typeof window !== 'undefined') {
      let subdomain = window.location.hostname.split('.')[0];
      if (STOP_SUBDOMAINS.includes(subdomain)) return null;

      if (subdomain && subdomain.endsWith(TELEGRAM_SUBDOMAIN_SUFFIX)) {
        subdomain = subdomain.replace(TELEGRAM_SUBDOMAIN_SUFFIX, '');
      }

      if (subdomain === APP_SUBDOMAIN) {
        subdomain = router.query.community as string;
      }
      return {
        name: subdomain,
        subdomain: subdomain
      } as CurrentCommunityType;
    }
    return null;
  }, [currentUser, router.query.community]);

  return (
    <CommunityContext.Provider value={{ community }}>
      {children}
    </CommunityContext.Provider>
  );
};

export function useCommunity() {
  return useContext(CommunityContext);
}
